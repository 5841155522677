import React, { useContext, useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import Select from 'react-select';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import QnaApiService from './QnaApiService';
import { GlobalContext } from '../../library/Core/GlobalContext';

interface Vote {
    vote_type: number;
    answer_user_id: string;
}

interface Flag {
    is_appropriate: number;
    red_flag_user_id: string;
}

const ViewMoreQuestion: React.FC = () => {
    const [searchParams] = useSearchParams();
    const questionId = searchParams.get('question_id');
    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const user_id = sessionState?.studentId ?? sessionState?.userId;
    const userType = sessionState?.userTypeCode;

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: { messagesFromCode: null, messageTypeCode: null, filteredMessages: [], searchText: null },
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        getStudentProfile();
    }, []);
    
    const getStudentProfile = async () => {
        const params = `?question_id=${questionId}`;

        try {
            const response = await QnaApiService.viewMoreAnswer(params);
            const questions = response?.data.data.questions ?? [];
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { ...data, alumniStudentProfile: questions },
                    domain: globalState.domain,
                    internal,
                    flags: { isDataLoading: false }
                },
            });
        } catch (error) {
            console.error('Error fetching student profile:', error);
            setError('Failed to fetch student profile');
        }
    };


    const formatDate = (isoDateString: string): string => {
        const createdDate = new Date(isoDateString);

        return `${createdDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })} | ${createdDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })}`;
    };

    const handleRating = async (answer_id: string, type: string) => {
        const payload = {
            user_id,
            answer_id,
            type
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/likeDislike`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const result = await response.json();
                getStudentProfile();
                console.log('Rating submitted successfully:', result);
            } else {
                const result = await response.json();
                console.error('Failed to submit rating:', result.message);
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
        }
    };


    const handleRedFlag = async (red_answer_id: string, is_appropriate: string) => {
        console.log('red_answer_id' ,red_answer_id);
        const payload = {
            user_id,
            red_answer_id,
            is_appropriate
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/redFlag`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const result = await response.json();
                getStudentProfile();
                console.log('Red Flag submitted successfully:', result);
            } else {
                const result = await response.json();
                console.error('Failed to submit rating:', result.message);
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
        }
    };

    const alumniStudentProfile = state.data?.alumniStudentProfile || [];
    console.log('alumniStudentProfile' , alumniStudentProfile);
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div className='main flex-1 bg-white'>
                <div className='max-1140 px-lg-0 overflow-hidden py-4 my-2 mx-auto'>
                <div className="post-wrap gt">
                {alumniStudentProfile.map((query: any) => (
                    <div className="post-block">
                        <div className="row g-3">
                            <div className="col-auto">
                                <div className="post-img">
                                    <img src={query.profile_picture_file_path || 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/yellow-student-boy.svg'} width="60" alt={query.first_name} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="row g-2">
                                    <div className="col-12">
                                        <div className="row g-2">
                                            <div className="col-sm">
                                                <div className="post-head">POSTED BY</div>
                                                <div className="post-alumni-name">{query.first_name} {query.last_name}</div>
                                                <div className="post-alumni-batch">{query.program_name}, {query.pass_out_year}</div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="d-flex flex-column">
                                                    <span className="font-14 mb-3">{formatDate(query.created)}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="post-brife-answer">
                                            <div className="row g-2">
                                                <div className="col-sm">
                                                    <p className="brife">{query.description}</p>
                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                                <div className="col-12">
                                                    <div className="answered_wrap">
                                                        <div className="post-head">ANSWERED BY</div>
                                                        <div className="row g-2">
                                                        {query.answers?.map((answer: any) => (
                                                            <div key={answer.id} className="row g-2">
                                                                 {(answer.user_type === 'ALUMNI' || answer.user_type === 'STUDENT') && (
                                                                <div className="col-auto">
                                                                    <div className="post-img">
                                                                    <img src={answer.profile_picture_file_path || 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/yellow-student-boy.svg'} width="40" alt={answer.first_name} />
                                                                    </div>
                                                                </div>
                                                                 )}
                                                                <div className="col">
                                                                    <div className="row g-2">
                                                                        <div className="col-12">
                                                                            <div className="row g-2">
                                                                            {(answer.user_type === 'ALUMNI' || answer.user_type === 'STUDENT') && (    
                                                                            <div className="col-sm">
                                                                                <div className="post-alumni-name">{answer.first_name} {answer.last_name}</div>
                                                                                <div className="post-alumni-batch">{answer.program_name}, {answer.pass_out_year}</div>
                                                                            </div>
                                                                            )}
                                                                             {(answer.user_type === 'SUPER_ADMIN') && (  
                                                                                <div className="col-sm">
                                                                                    <div className="post-alumni-name">Counsellor</div>
                                                                                </div>
                                                                             )}
                                                                            {(answer.user_type === 'INSTITUTE_ADMIN') && (  
                                                                              <div className="col-sm">
                                                                                <div className="post-alumni-name">{answer.designation}</div>
                                                                              </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <p>
                                                                            {answer.answer}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="message_date">
                                                                                <input 
                                                                                    type="radio" 
                                                                                    className="d-none sss" 
                                                                                    name={`post_rating_${answer.answer_id}`} 
                                                                                    value="like" 
                                                                                    id={`like${answer.answer_id}`} 
                                                                                    onClick={() => handleRating(answer.answer_id, '1')} 
                                                                                    checked={answer.votes.some((vote: Vote) => vote.vote_type === 1 && vote.answer_user_id === user_id)}
                                                                                />
                                                                                <label className="icon-like font-20 like me-1" htmlFor={`like${answer.answer_id}`}></label>
                                                                                <input 
                                                                                    type="radio" 
                                                                                    className="d-none" 
                                                                                    name={`post_rating_${answer.answer_id}`} 
                                                                                    value="dislike" 
                                                                                    id={`dislike${answer.answer_id}`} 
                                                                                    onClick={() => handleRating(answer.answer_id, '2')} 
                                                                                    checked={answer.votes.some((vote: Vote) => vote.vote_type === 2 && vote.answer_user_id === user_id)}
                                                                                />
                                                                                <label className="icon-like font-20 dislike" htmlFor={`dislike${answer.answer_id}`}></label>
                                                                            </div>
                                                                        </div>
                                                                            
                                                                        {userType === 'ALUMNI' && (
                                                                        <>                                                                            <div className="col-auto"><span className="horizontal-divider"></span></div>
                                                                            <div className="col-auto">
                                                                                <div className="mark-flag">
                                                                                    <input 
                                                                                        type="checkbox" 
                                                                                        id={`mark-${answer.answer_id}`} 
                                                                                        className="d-none"
                                                                                        onChange={() => handleRedFlag(answer.answer_id, '1')} 
                                                                                        checked={answer.red_flag.some((flag: Flag) => flag.is_appropriate === 1 && flag.red_flag_user_id === user_id)}
                                                                                    />
                                                                                    <label htmlFor={`mark-${answer.answer_id}`}>
                                                                                        <i className="icon-flag"></i>
                                                                                        <span>Flag as inappropriate</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                        )}

                                                                        {query.answers?.length <= 3 && (
                                                                        <div className="col-md col-sm-auto d-flex justify-content-end">
                                                                            <div className="tags-wrap d-flex flex-wrap">
                                                                                {query.tags?.split(', ').map((tag: string, index: number) => (
                                                                                    <span key={index}>{tag}</span>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                            <div className="col-12">
                                                                <div className="row g-2">
                                                                    
                                                                    <div className="col-md col-sm-auto d-flex justify-content-end">
                                                                        <div className="tags-wrap d-flex flex-wrap">
                                                                        {query.tags.split(', ').map((tag: string, index: number) => (
                                                                            <span key={index}>{tag}</span>
                                                                        ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
                </div>
            </div>
            
            <DebugBarControl />
        </SmartContext.Provider>
    );
};

export default ViewMoreQuestion;
