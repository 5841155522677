import { useContext, useEffect, useState } from 'react';
import AppSettingsService from '../../services/app-settings.service';
import useUserActivity from '../../services/use-user-activity';
// import DarkLogo from '../../styles/images/dark-logo.svg';
import SessionContext from '../Core/SessionContext';
import CardBottom from '../../features/alumni/alumni-directory/CardBottom';
import { getStudentImage } from '../../common/styles/Images';
import { SmartContext } from '../Core/SmartContext';
import { axiosClient } from '../Service/axiosClient';
import { useNavigate } from 'react-router-dom';
import smartReducer from '../Core/SmartReducer';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../Core/SmartTypes';
import { isEmpty } from '../Core/SmartFunctions';
import { useLocation } from 'react-router-dom';

const DarkLogo = `${process.env.REACT_APP_IMAGE_BASEPATH}/dark-logo.svg`;


const PageFooterControl = () => {
    const location = useLocation();
    const { sessionState } = useContext(SessionContext);
    const [isFooterModalOpen, setFooterModalOpen] = useState(false);
    // const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: { filters: {} },
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const handleInactivity = () => {
        console.log('User is inactive!');
        sessionStorage.clear();
        sessionStorage.setItem('user-logged-out', 'TRUE');
        // localStorage.setItem('user-logged-out', 'TRUE');
        // navigate('/login'); // redirect to login or any other action you want
        // window.location.href = AppSettingsService.getLoginUrlFromStorage() as string;
        window.location.href = AppSettingsService.getLoginUrl(sessionState) as string;
    };

    useUserActivity(300 * 60 * 1000, handleInactivity);

    //   if(sessionState?.userTypeCode === 'STUDENT'){

    interface AlumniMatch {
        uuid: string;
        firstName: string;
        lastName: string;
        programName: string;
        passOutYear: number;
    }

    interface MatchAlum {
        uuid : string;
        name : string;
        programName : string;
        passOutYear : number;
    }

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/${id}`);
    };

    const handleSendMessageMultiple = (event: any, uiid: any) => {
        // var uiid = uiid.join('/');
        updateNextBatchAlumni();
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/${uiid}`);
        setFooterModalOpen(false);
    };

    const updateNextBatchAlumni = async () => {
        const studentId = sessionState?.studentId;
        try {
            const response = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/update-next-alumni-batch/${studentId}`);
        } catch (error) {
            console.log(error);
        }
    };

    const openFooterModal = () => {
        setFooterModalOpen(true);
    };

    const closeFooterModal = () => {
        setFooterModalOpen(false);
    };

    const [topAlumni, setmatchAlum] = useState<MatchAlum[] | null>(null);
    const [multipleUiid, setmultipleUiid] = useState<string>(''); 
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const studentId = sessionState?.studentId;
                const response = await axiosClient().get(
                    `${process.env.REACT_APP_REPORTS_SERVER}/common/alumni-top-match/${studentId}`
                );
                setmatchAlum(response.data.fetchedAlumni);
            } catch (err) {
                console.log('ALumni Guidance Error');
                console.log(err);
            }
        };
        fetchData();
    }, [isFooterModalOpen]); 
    
    useEffect(() => {
        if (topAlumni && topAlumni.length > 0) {
            const uuids = topAlumni.map((alum: any) => alum.uuid).join('/');
            setmultipleUiid(uuids);
        }
    }, [topAlumni]); // This useEffect triggers whenever topAlumni is updated
    

    return (
        <>
            <footer className="footer">
                <div className="footer-wrap">
                    <div className="row align-items-center">
                        <div className="col-sm">
                            <div className="copyRight">
                                International Educational Gateway Pvt. Ltd. © 2023. All Rights Reserved. Univariety
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <div className="powerby">
                                <span>Powered By</span>
                                <img src={DarkLogo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div>
                {sessionState?.userTypeCode === 'STUDENT' && (
                    <>
                        {!isEmpty(topAlumni) && !location.pathname.includes('directory') &&  (
                            <a href="javascript:;" className="btn-alumni-guidance" onClick={() => openFooterModal()} >
                               <i></i> <span>ALUMNI GUIDANCE</span>
                            </a>
                        )}
                        <div
                            className={`modal fade show vh-100  ${isFooterModalOpen ? 'show' : ''}`}
                            id="alumni-guidance"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabelvideo"
                            aria-hidden="true" 
                            style={{ display: isFooterModalOpen ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                            <div className="modal-dialog modal-dialog-centered max-1140" role="document">
                                <div className="modal-content radius-6">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Chat With The Alumni For Guidance Now</h5>
                                        <button
                                            type="button"
                                            className="btn-close m-1 position-absolute right20 top20 z9"
                                            onClick={() => closeFooterModal()}></button>
                                    </div>

                                    <div className="modal-body p-4 text-capitalize">
                                    <div className="row g-2 g-md-4 alumni-showcase">
    
    {topAlumni && topAlumni.length > 0 ? (
        <>
            {topAlumni.map((alum) => (
                <div className="col-lg-4 col-md-6 col-sm-6" key={alum.uuid}>
                    <div className="alumni-bucket">
                        <div className="alumni_class_link">
                            <div className="alumni_name_detail">
                                <div className="alumni_img">
                                    <a className="alumni_img_link" href="javascript:;">
                                        <img
                                            src={getStudentImage(alum)}
                                            alt={`${alum.name}`}
                                        />
                                    </a>
                                </div>
                                <div className="alumni_name_brife">
                                    <h3>
                                        <a className="alumni_name_link" href="javascript:;">
                                            {alum.name}                                       </a>
                                    </h3>
                                    <span className="alumni_campus">
                                        {alum.programName}, {alum.passOutYear}
                                    </span>
                                </div>
                            </div>
                            <div className="send_link">
                                <a
                                    role="button"
                                    onClick={(event) => handleSendMessage(event, alum.uuid)}
                                    className="btn-send">
                                    <i className="icon-alumni-send"></i>
                                    <span>Send</span>
                                </a>
                            </div>
                        </div>
                        <CardBottom alumni={alum} state={state} />
                    </div>
                </div>
            ))}
            <div className="col-12 text-center d-flex justify-content-center">
                <button className="btn btn-green">
                    <span onClick={(event) => handleSendMessageMultiple(event, multipleUiid)}>
                        Send to All
                    </span>
                    <i className="icon-alumni-send ms-2 position-relative"></i>
                </button>
            </div>
        </>
    ) : (
        <div className="col-12 text-center">
            <p>No data available</p>
        </div>
    )}
                                     
                                     </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default PageFooterControl;
